// eslint-disable @typescript-eslint/no-unused-vars
// @ts-ignore

import * as React from 'react';
import classnames from 'classnames';
import { MenuConfigItem, MenuProps } from './models';
import { makeAbsUrl } from '../../../common/utilities/makeAbsUrl';
import { IActionContext } from '@msdyn365-commerce/core';

const mergeMenuItems = (items: MenuConfigItem[]) => {
    const mergedItems: MenuConfigItem[] = [];
    for (const m of items) {
        if (m.label) {
            mergedItems.push({
                ...m
            });
        } else {
            const lastItem = mergedItems[mergedItems.length - 1];
            if (lastItem) {
                mergedItems[mergedItems.length - 1] = {
                    ...lastItem,
                    children: [...(lastItem.children ?? []), ...(m.children ?? [])]
                };
            } else {
                console?.warn('invalid menu config', items);
            }
        }
    }

    for (const m of mergedItems) {
        m.children = mergeMenuItems(m.children ?? []);
    }

    return mergedItems;
};

const MenuList = ({ menus, level, context }: { menus: MenuConfigItem[]; level: number; context: IActionContext }) => {
    const [activeMenu, setActiveMenu] = React.useState<MenuConfigItem | null>(null);
    return (
        <ul className={classnames(`menu-lvl-${level}`)}>
            {menus.map((menuItem, idx) => {
                const hasChild = Boolean(menuItem.children && menuItem.children.length > 0);
                const expanded = hasChild && activeMenu === menuItem;
                return (
                    <li
                        key={idx}
                        className={classnames(menuItem.className, {
                            active: expanded
                        })}
                    >
                        {hasChild ? (
                            <span onClick={() => setActiveMenu(expanded ? null : menuItem)}>{menuItem.label}</span>
                        ) : menuItem.url ? (
                            <a href={makeAbsUrl(context, menuItem.url)} target={menuItem.target}>
                                {menuItem.label}
                            </a>
                        ) : (
                            <>{menuItem.label}</>
                        )}
                        {hasChild && (
                            <button type='button' onClick={() => setActiveMenu(expanded ? null : menuItem)}>
                                {expanded ? <div className='menu-icon icon-minus'></div> : <div className='menu-icon icon-plus'></div>}
                            </button>
                        )}
                        {expanded && <MenuList menus={menuItem.children!} level={level + 1} context={context} />}
                    </li>
                );
            })}
        </ul>
    );
};

const MobileMenu = ({ menuConfig, className, context }: MenuProps) => {
    const rootItems = React.useMemo(() => mergeMenuItems(menuConfig), []);
    // const [activeMenu, setActiveMenu] = React.useState<MenuConfigItem | null>(null);
    // const menuItems = activeMenu ? activeMenu.children ?? [] : rootItems;

    return (
        <div className={classnames(`hei-navi-menu`, className, 'mobile')}>
            <MenuList menus={rootItems} context={context} level={1} />
            {/* {menuItems.map((menuItem, idx) => {
                const hasChild = Boolean(menuItem.children && menuItem.children.length > 0);
                return (
                    <li key={idx} className={classnames(menuItem.className)}>
                        {menuItem.url ? <a href={makeAbsUrl(context, menuItem.url)}>{menuItem.label}</a> : <>{menuItem.label}</>}
                        {hasChild && (
                            <button type='button' onClick={() => setActiveMenu(menuItem)}>
                                +
                            </button>
                        )}
                    </li>
                );
            })} */}
        </div>
    );
};

export default MobileMenu;
